import Slider from 'react-slick';
import { useTheme } from '@mui/system';
import 'slick-carousel/slick/slick.css';
import { brandColor } from 'themes/constants';
import clock from '../../../assets/clock.svg';
import 'slick-carousel/slick/slick-theme.css';
import genlogo from '../../../assets/genlogo.png';
import LaterPredictionCard from './LaterPredictionCard.';
import { Grid, Typography, Card, Box } from '@mui/material';
import UpcomingPredictionCard from './UpcomingPredictionCard';
import FinishedPredictionCard from './FinishedPredictionCard';

const PredictionsMain = () => {
    const theme = useTheme();
    const settings = {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        swipe: true,
        arrows: true,
        touchMove: true,
        initialSlide: 1,
        centerMode: true
    };
    return (
        <>
            <Grid container display="flex" justifyContent="center" alignItems="center" mt={25} mb={5}>
                <Grid item display="flex" justifyContent="space-between" flexDirection="row" md={11.5} sm={9} xs={11.7} lg={4.9}>
                    <Grid item display="flex" flexDirection="row" alignItems="center">
                        <Grid item mr={-2} display="flex" alignItems="center" zIndex={999}>
                            <img src={genlogo} alt="logo" width={55} />
                        </Grid>
                        <Card
                            sx={{
                                background: theme.palette.card.main,
                                border: 1,
                                px: 2,
                                py: 1,
                                alignItems: 'center',
                                borderTopRightRadius: 10,
                                borderBottomRightRadius: 10,
                                display: 'flex'
                            }}
                        >
                            <Typography
                                sx={{ color: brandColor, fontWeight: 700, mr: 3, ml: 0.5, fontSize: { lg: 16, md: 16, sm: 14, xs: 12 } }}
                            >
                                GENUSD
                            </Typography>
                            <Typography
                                sx={{
                                    color: theme.palette.text.invertedthird,
                                    fontSize: { lg: 14, md: 14, sm: 12, xs: 12 },
                                    fontWeight: 700
                                }}
                            >
                                $579.8402
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item display="flex" flexDirection="row" alignItems="center">
                        <Card
                            sx={{
                                background: theme.palette.card.main,
                                border: 1,
                                py: 1,
                                pl: 1,
                                pr: 3,
                                alignItems: 'center',
                                borderTopLeftRadius: 10,
                                borderBottomLeftRadius: 10,
                                display: 'flex'
                            }}
                        >
                            <Typography sx={{ color: brandColor, fontWeight: 700, mr: 0.75, fontSize: { lg: 16, md: 16, sm: 14, xs: 12 } }}>
                                03:28
                            </Typography>
                            <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 12, fontWeight: 700 }}>5m</Typography>
                        </Card>
                        <Grid item ml={-2.5} display="flex" alignItems="center" zIndex={999}>
                            <img src={clock} alt="logo" width={55} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ width: '40%' }}>
                <Slider {...settings}>
                    <Grid item>
                        <FinishedPredictionCard />
                    </Grid>
                    <Grid item>
                        <UpcomingPredictionCard />
                    </Grid>
                    <Grid item>
                        <LaterPredictionCard />
                    </Grid>
                </Slider>
            </Box>
        </>
    );
};

export default PredictionsMain;
