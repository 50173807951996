import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { forwardRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';
import { Link, useLocation } from 'react-router-dom';
import { Grid, ListItemButton, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions';
import config from 'config';
import { grey, orange } from '@mui/material/colors';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const location = useLocation(); // Hook to get current URL path
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={`${config.basename}${item.url}`} target={itemTarget} />)
    };

    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
        if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);

        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
    }, [dispatch, item.id]);

    // Determine if the current item is selected based on the URL
    const isSelected = location.pathname === `${config.basename}${item.url}`;

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                '&:hover': { bgcolor: 'transparent' },
                alignItems: 'center',
                width: 'auto'
            }}
            onClick={() => itemHandler(item.id)}
        >
            <Grid item container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircleIcon sx={{ color: orange[700], fontSize: 9, mr: 0.5, display: isSelected ? 'flex' : 'none' }} />
                <ListItemText
                    primary={
                        <Typography
                            fontWeight={600}
                            textAlign="left"
                            color={isSelected ? grey[100] : grey[400]}
                            sx={{
                                fontSize: { lg: 16, md: 16, sm: 13, xs: 13 },
                                color: {
                                    lg: isSelected ? grey[100] : grey[400],
                                    md: isSelected ? grey[100] : grey[400],
                                    sm: isSelected ? grey[900] : grey[600],
                                    xs: isSelected ? grey[900] : grey[600]
                                },
                                ':hover': { color: { lg: grey[100], md: grey[100], sm: grey[900], xs: grey[900] } }
                            }}
                        >
                            {item.title}
                        </Typography>
                    }
                />
            </Grid>
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
