import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import { Grid, Typography, Button } from '@mui/material';
import banner from '../../../assets/empowergenbanner.png';

const DiscoverMore = () => {
    const theme = useTheme();

    return (
        <>
            <Grid container display="flex" justifyContent="center" mt={15}>
                <Grid container display="flex" lg={9} md={10} ml={4}>
                    <Typography textAlign="center" sx={{ fontSize: 16, color: grey[900], fontWeight: 600 }}>
                        Discover More
                    </Typography>
                </Grid>
                <Grid
                    container
                    lg={9}
                    md={10}
                    sm={11}
                    xs={11.5}
                    px={5}
                    py={4}
                    mt={2}
                    zIndex={999}
                    display="flex"
                    sx={{
                        borderRadius: 7,
                        background: `linear-gradient(to right bottom, #070707, #292929)`,
                        justifyContent: { lg: 'space-between', md: 'space-between', sm: 'center', xs: 'center' }
                    }}
                >
                    <Grid
                        container
                        width="70%"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ display: { lg: 'none', md: 'none', sm: 'flex', xs: 'flex' } }}
                    >
                        <img src={banner} alt="banner" width="70%" />
                    </Grid>
                    <Grid container display="flex" sx={{ width: { lg: '50%', md: '60%', sm: '100%', xs: '100%' } }}>
                        <Typography sx={{ fontSize: 24, color: grey[50], fontWeight: 700, mt: { lg: 0, md: 0, sm: 3, xs: 3 } }}>
                            Empowering Decentralization with GEN
                        </Typography>
                        <Grid container width="auto" mt={2}>
                            <Typography sx={{ fontSize: 14, color: grey[300], fontWeight: 500 }}>
                                Usual is set to redefine DeFi, merging innovative finance solutions with seamless integration across the
                                ecosystem. Our focus on usability ensures that every Usual product is essential, adaptable, and widely
                                adopted.
                            </Typography>
                        </Grid>
                        <Button
                            sx={{ background: grey[50], mt: 5, color: grey[900], px: 3, mb: 1, borderRadius: 50, textTransform: 'none' }}
                        >
                            Join the ecosystem
                        </Button>
                    </Grid>
                    <Grid
                        container
                        width="35%"
                        alignItems="center"
                        justifyContent="right"
                        sx={{ display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' } }}
                    >
                        <img src={banner} alt="banner" width="70%" />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default DiscoverMore;
