import { useEffect } from 'react';
import { Grid } from '@mui/material';
import StakingCard from './StakingCard';
import GenDescription from './GENDescription';
import UsdcStakingCard from './UsdcStakingCard';
import bg from '../../../assets/DashboardBanner.png';
import GenStakingStats from '../Dashboard/GenStakingStats';

const StakingMain = () => {
    useEffect(() => {
        document.title = 'Stake | Generic';
    });
    return (
        <>
            <Grid
                container
                display="flex"
                justifyContent="center"
                bgcolor="#000"
                height={50}
                sx={{ mt: { lg: 9.65, md: 9.65, xs: 8.5 } }}
            />
            <Grid
                container
                display="flex"
                justifyContent="center"
                bgcolor="#f8f8ff"
                sx={{ borderTopLeftRadius: 23, borderTopRightRadius: 23, mt: -6.3, pt: 10 }}
            >
                <Grid
                    item
                    sx={{
                        top: 0,
                        zIndex: 1,
                        width: '90%',
                        height: '100%',
                        display: 'flex',
                        overflow: 'hidden',
                        position: 'absolute',
                        justifyContent: 'center'
                    }}
                >
                    <img
                        src={bg} // Path to your background image
                        alt="Background"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover', // Ensures the image covers the entire container
                            filter: 'brightness(93%)'
                        }}
                    />
                </Grid>
                <StakingCard />
                <UsdcStakingCard />
            </Grid>
            <Grid container display="flex" justifyContent="center">
                <GenStakingStats />
            </Grid>
            <Grid container display="flex" justifyContent="center">
                <GenDescription />
            </Grid>
        </>
    );
};

export default StakingMain;
