import { lighten } from '@mui/system';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { useWeb3React } from '@web3-react/core';
import CircleIcon from '@mui/icons-material/Circle';
import AnalyticsOverview from './AnalyticsOverview';
import bg from '../../../assets/DashboardBanner.png';
import { Grid, Typography, TableBody, TableCell, TableContainer, Table, TableRow, TableHead, Paper } from '@mui/material';
import { balanceOfGEN, deadWallet, fetchGENPrice, genManagerAddress, lpPairTokenAddress } from 'components/wallet/sharesABI';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const AnalyticsMain = () => {
    const { account } = useWeb3React();
    const [burnt, setBurnt] = useState(0);
    const [reserves, setReserves] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalLocked, setTotalLocked] = useState(0);
    const [activeIndex, setActiveIndex] = useState(null);
    const genPrice = loading ? 0 : reserves[1] / reserves[0];
    const [liquidityBalance, setLiquidityBalance] = useState(0);
    const isDataReady = liquidityBalance && burnt && totalLocked;
    const circulatingBalance = 21000000 * 10 ** 18 - liquidityBalance - burnt - totalLocked;

    useEffect(() => {
        // Define the function to fetch rewards and update state
        const updateData = async () => {
            try {
                const price = await fetchGENPrice();
                const burnt = await balanceOfGEN(deadWallet);
                const locked = await balanceOfGEN(genManagerAddress);
                const liquidity = await balanceOfGEN(lpPairTokenAddress);
                setBurnt(burnt);
                setLoading(false);
                setReserves(price);
                setTotalLocked(locked);
                setLiquidityBalance(liquidity);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching rewards:', error);
            }
        };

        updateData();
    }, [account]);

    const data = [
        { name: 'Liquidity', value: parseFloat(liquidityBalance), addres: '0x8...9x8f' },
        { name: 'Burnt', value: parseFloat(burnt), addres: '0xF32...744O' },
        { name: 'Locked', value: parseFloat(totalLocked), addres: '0xF32...744O' },
        { name: 'Circulating', value: parseFloat(circulatingBalance), addres: 'Explorer' }
    ];

    // Handle mouse enter event
    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    // Handle mouse leave event
    const onPieLeave = () => {
        setActiveIndex(null);
    };
    useEffect(() => {
        document.title = 'Analytics | Generic';
    });
    return (
        <>
            <Grid
                item
                sx={{
                    top: 0,
                    zIndex: 1,
                    width: '90%',
                    height: '100%',
                    display: 'flex',
                    overflow: 'hidden',
                    position: 'absolute',
                    justifyContent: 'center'
                }}
            >
                <img
                    src={bg} // Path to your background image
                    alt="Background"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensures the image covers the entire container
                        filter: 'brightness(93%)'
                    }}
                />
            </Grid>
            <AnalyticsOverview />
            <Grid container display="flex" justifyContent="center">
                <Grid
                    container
                    sx={{
                        py: 2,
                        px: 3,
                        mt: 3,
                        zIndex: 999,
                        display: 'flex',
                        borderRadius: 5,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: { lg: 700, md: 700, xs: '97.5%' },
                        flexDirection: { xs: 'column', md: 'row' },
                        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                        background: 'linear-gradient(to right bottom, #070707, #272727)'
                    }}
                >
                    <Typography sx={{ color: grey[100], fontWeight: 700, fontSize: 24 }}>Supply Info</Typography>
                    <Typography sx={{ color: grey[100], fontWeight: 600, letterSpacing: '-1px', fontSize: 24 }}>
                        ${(genPrice * 21000000).toLocaleString('en-US', { maximumFractionDigits: 0 })}
                    </Typography>
                    {isDataReady ? (
                        <Grid
                            container
                            mt={3}
                            display="flex"
                            alignItems="center"
                            width="100%"
                            justifyContent="space-between"
                            sx={{ flexDirection: 'row' }}
                        >
                            {/* Pie Chart and Details in the center */}
                            <Grid
                                container
                                justifyContent="center"
                                display="flex"
                                position="relative"
                                sx={{ width: { lg: '30%', md: '30%', sm: '100%', xs: '100%' } }}
                            >
                                <PieChart width={200} height={200}>
                                    <Pie
                                        data={data}
                                        dataKey="value"
                                        cx="50%"
                                        cy="50%"
                                        paddingAngle={2}
                                        outerRadius={90}
                                        innerRadius={60}
                                        cornerRadius={8}
                                        onMouseEnter={onPieEnter}
                                        onMouseLeave={onPieLeave}
                                        fill="#8884d8"
                                    >
                                        {data.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={
                                                    activeIndex === index || activeIndex === null
                                                        ? COLORS[index % COLORS.length]
                                                        : grey[100]
                                                }
                                            />
                                        ))}
                                    </Pie>
                                </PieChart>

                                {activeIndex !== null && (
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        flexDirection="column"
                                        sx={{
                                            background: lighten(COLORS[activeIndex], 0.97),
                                            borderRadius: '50%',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            height: '56%',
                                            width: '56%',
                                            transform: 'translate(-50%, -50%)',
                                            textAlign: 'center',
                                            pointerEvents: 'none'
                                        }}
                                    >
                                        <Typography textAlign="center" sx={{ color: grey[900], fontSize: 10 }}>
                                            {(data[activeIndex].value / 10 ** 18).toLocaleString('en-US', {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            })}{' '}
                                            GEN
                                        </Typography>
                                        <Typography textAlign="center" sx={{ color: COLORS[activeIndex], fontSize: 26, fontWeight: 800 }}>
                                            {((data[activeIndex].value / (21000000 * 10 ** 18)) * 100).toFixed(2)}%
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <TableContainer
                                component={Paper}
                                sx={{
                                    ml: 2,
                                    boxShadow: 0,
                                    background: 'transparent',
                                    maxWidth: { lg: '60%', md: '70%', sm: '100%', xs: '100%' }
                                }}
                            >
                                <Table sx={{ minWidth: 150, borderCollapse: 'collapse' }}>
                                    <TableHead>
                                        <TableRow sx={{ borderBottom: 'none' }}>
                                            <TableCell sx={{ border: 'none' }}>
                                                <Typography sx={{ color: grey[500], fontSize: 12, fontWeight: 700 }}>Type</Typography>
                                            </TableCell>
                                            <TableCell sx={{ border: 'none' }}>
                                                <Typography sx={{ color: grey[500], fontSize: 12, fontWeight: 700 }}>Amount</Typography>
                                            </TableCell>
                                            <TableCell sx={{ border: 'none', width: 'auto' }}>
                                                <Typography sx={{ color: grey[500], fontSize: 12, fontWeight: 700 }}>
                                                    Value (USD)
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((row, index) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 'none' }, borderBottom: 'none' }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ border: 'none' }}>
                                                    <Typography
                                                        sx={{
                                                            color: grey[100],
                                                            fontSize: 12,
                                                            fontWeight: 700,
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <CircleIcon sx={{ color: COLORS[index], fontSize: 14, mr: 0.3 }} />
                                                        {row.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left" sx={{ border: 'none' }}>
                                                    <Typography sx={{ color: grey[100], fontSize: 12, fontWeight: 700, display: 'flex' }}>
                                                        {(row.value / 10 ** 18).toLocaleString('en-US', {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2
                                                        })}{' '}
                                                        GEN
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left" sx={{ border: 'none' }}>
                                                    <Typography sx={{ color: grey[100], fontSize: 12, fontWeight: 700 }}>
                                                        $
                                                        {((row.value / 10 ** 18) * genPrice).toLocaleString('en-US', {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2
                                                        })}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    ) : (
                        <Typography sx={{ color: grey[500], fontSize: 12, fontWeight: 700 }}>Loading...</Typography>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default AnalyticsMain;
