import { FaCaretUp } from 'react-icons/fa';
import { grey, red } from '@mui/material/colors';
import { useTheme, styled } from '@mui/system';
import genlogo from '../../../assets/genlogo.png';
import { LinearProgress, Grid, Typography, Box } from '@mui/material';

const GradientProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: 10, // Unfilled part height
    borderRadius: 3,
    backgroundColor: grey[800],
    position: 'relative',
    overflow: 'hidden',
    '& .MuiLinearProgress-bar': {
        background: 'linear-gradient(to right, #ff6a00, #ee0979)', // Gradient for filled part
        boxShadow: '0 4px 20px rgba(238, 9, 121, 0.8)', // Adds shadow to make it stand out
        borderRadius: 8 // Rounded edges for the filled part
    }
}));

const GenericToken = () => {
    const theme = useTheme();

    return (
        <>
            <Grid container display="flex" justifyContent="center" mt={15}>
                <Grid container display="flex" justifyContent="space-between" lg={9} md={10} sm={11} xs={11.5}>
                    <Typography textAlign="center" sx={{ fontSize: 16, color: grey[900], fontWeight: 600, ml: 4 }}>
                        Generic Token
                    </Typography>
                    <Typography textAlign="center" sx={{ fontSize: 16, color: grey[900], fontWeight: 600, mr: 4 }}>
                        Learn more
                    </Typography>
                </Grid>
                <Grid
                    container
                    lg={9}
                    md={10}
                    sm={11}
                    xs={11.5}
                    mt={2}
                    zIndex={999}
                    display="flex"
                    justifyContent="space-between"
                    sx={{
                        background: `linear-gradient(to right bottom, #141414, #141414)`,
                        borderRadius: 7,
                        overflow: 'hidden',
                        px: { lg: 5, md: 5, sm: 4, xs: 2 }
                    }}
                >
                    <Grid container lg={6.5} display="flex" alignItems="center" pt={4} sx={{ px: { lg: 0, md: 0, sm: 0, xs: 3 } }}>
                        <Typography sx={{ color: grey[50], fontWeight: 600, fontSize: 20, mr: 0.5 }}>Introducing</Typography>
                        <img src={genlogo} alt="logo" height={38} width={38} />
                        <Typography sx={{ color: grey[50], fontWeight: 700, fontSize: 20, ml: 1 }}>GEN</Typography>
                        <Typography sx={{ color: grey[50], fontWeight: 700, fontSize: 20, ml: 1 }}>Token</Typography>
                        <Grid container width="100%" mt={2}>
                            <Typography sx={{ color: grey[200], fontSize: 16 }}>
                                The $GEN token is the core utility token of GenericDAO, enabling holders to stake for high-yield GEN or
                                Bitcoin rewards or participate in governance through voting. With its limited supply and Bitcoin-backed
                                liquidity, $GEN is designed to store value.
                            </Typography>
                        </Grid>
                        <Grid container width="100%" my={4} sx={{ ml: { lg: 2, md: 2, sm: 2, xs: 0 } }} display="flex">
                            <Grid
                                container
                                display="flex"
                                flexDirection="column"
                                sx={{
                                    borderLeft: { lg: 0, md: 0, sm: 0, xs: 1 },
                                    borderColor: { xs: grey[800] },
                                    pl: { lg: 0, md: 0, sm: 0, xs: 2 },
                                    width: { lg: 'auto', md: 'auto', sm: 'auto', xs: '100%' }
                                }}
                            >
                                <Typography sx={{ color: grey[500], fontWeight: 800, fontSize: 16 }}>Price</Typography>
                                <Typography sx={{ color: grey[50], fontSize: 16 }}>$0.02</Typography>
                            </Grid>
                            <Grid
                                container
                                display="flex"
                                flexDirection="column"
                                sx={{
                                    my: { lg: 0, md: 0, sm: 0, xs: 2 },
                                    pl: { lg: 4, md: 4, sm: 4, xs: 2 },
                                    pr: { lg: 4, md: 4, sm: 4, xs: 0 },
                                    mx: { lg: 4, md: 4, sm: 4, xs: 0 },
                                    borderLeft: { lg: 1.3, md: 1.3, sm: 1.3, xs: 1 },
                                    borderColor: { xs: grey[800] },
                                    borderRight: { lg: 1.3, md: 1.3, sm: 1.3, xs: 0 },
                                    width: { lg: 'auto', md: 'auto', sm: 'auto', xs: '100%' }
                                }}
                            >
                                <Typography sx={{ color: grey[500], fontWeight: 800, fontSize: 16 }}>Market Cap</Typography>
                                <Typography sx={{ color: grey[50], fontSize: 16 }}>$148.28K</Typography>
                            </Grid>
                            <Grid
                                container
                                display="flex"
                                flexDirection="column"
                                sx={{
                                    borderLeft: { lg: 0, md: 0, sm: 0, xs: 1 },
                                    borderColor: { xs: grey[800] },
                                    pl: { lg: 0, md: 0, sm: 0, xs: 2 },
                                    width: { lg: 'auto', md: 'auto', sm: 'auto', xs: '100%' }
                                }}
                            >
                                <Typography sx={{ color: grey[500], fontWeight: 800, fontSize: 16 }}>Current Fee</Typography>
                                <Typography sx={{ color: grey[50], fontSize: 16 }}>2%</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            mt: -4,
                            width: { lg: 300 },
                            height: 300,
                            overflow: 'hidden',
                            position: 'relative',
                            display: { lg: 'flex', md: 'none', sm: 'none', xs: 'none' }
                        }}
                    >
                        <img
                            src={genlogo}
                            alt="logo"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover', // Ensures the image scales properly
                                position: 'absolute', // Stretches the image to the container
                                top: 0,
                                left: 0
                            }}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                background: 'linear-gradient(to top, #141414, transparent)', // Black to transparent gradient
                                pointerEvents: 'none' // Ensures the gradient doesn’t block interactions
                            }}
                        />
                    </Grid>
                    <Grid container display="flex" justifyContent="center" mt={2} sx={{ mx: { lg: 0, md: 0, sm: 0, xs: 3 } }}>
                        <Box width="100%">
                            <GradientProgressBar variant="determinate" value={63} />
                        </Box>
                        <Grid container display="flex" justifyContent="space-between" mt={2}>
                            <Typography sx={{ color: grey[200], fontSize: 16 }}>63% Staked</Typography>
                            <Typography sx={{ color: grey[200], fontSize: 16 }}>37% Circulating</Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        display="flex"
                        mt={4}
                        sx={{
                            mb: { lg: 4.5, md: 4.5, sm: 3.5, xs: 2 },
                            justifyContent: { lg: 'space-between', md: 'space-between', sm: 'center', xs: 'center' }
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: 4,
                                background: '#222222',
                                mr: { lg: 0, md: 0, sm: 1, xs: 0 },
                                width: { lg: '33%', md: '33%', sm: '49%', xs: '100%' },
                                px: 3,
                                py: 2.5
                            }}
                        >
                            <Typography sx={{ color: grey[50], fontWeight: 700, fontSize: 22 }}>Real Ownership</Typography>
                            <Typography sx={{ color: grey[400], fontSize: 15, mt: 1 }}>
                                GEN token stakers gain governance rights and are eligible to use the Generic Improvement Proposals (GIP),
                                enabling them to influence fees and make key decisions about the protocol’s developments.
                            </Typography>
                            <Grid container display="flex" justifyContent="space-between" mt={6} mb={1}>
                                <Typography sx={{ color: grey[50], fontWeight: 600, fontSize: 16 }}>Transaction Fee</Typography>
                                <Typography sx={{ color: grey[50], fontWeight: 600, fontSize: 16, display: 'flex', alignItems: 'center' }}>
                                    <FaCaretUp style={{ fontSize: 20, color: '#d50000', marginRight: 5 }} /> 2.00%
                                </Typography>
                            </Grid>
                        </Box>
                        <Box
                            sx={{
                                px: 3,
                                py: 2.5,
                                borderRadius: 4,
                                background: '#222222',
                                my: { lg: 0, md: 0, sm: 0, xs: 1 },
                                width: { lg: '33%', md: '33%', sm: '49%', xs: '100%' }
                            }}
                        >
                            <Typography sx={{ color: grey[50], fontWeight: 700, fontSize: 22 }}>Revenue Generator</Typography>
                            <Typography sx={{ color: grey[400], fontSize: 15, mt: 1 }}>
                                The Treasury serves as an asset manager, constantly gathering protocol fees and leveraging them for
                                liquidity farming and other strategies to generate sustainable revenue.
                            </Typography>
                            <Grid container display="flex" justifyContent="space-between" mt={6} mb={1}>
                                <Typography sx={{ color: grey[50], fontWeight: 600, fontSize: 16 }}>TVL</Typography>
                                <Typography sx={{ color: grey[50], fontWeight: 600, fontSize: 16, display: 'flex', alignItems: 'center' }}>
                                    <FaCaretUp style={{ fontSize: 20, color: '#00e676', marginRight: 5 }} /> $148.48K
                                </Typography>
                            </Grid>
                        </Box>
                        <Box
                            sx={{
                                borderRadius: 4,
                                background: '#222222',
                                mt: { lg: 0, md: 0, sm: 1, xs: 0 },
                                width: { lg: '33%', md: '33%', sm: '50%', xs: '100%' },
                                px: 3,
                                py: 2.5
                            }}
                        >
                            <Typography sx={{ color: grey[50], fontWeight: 700, fontSize: 22 }}>Scarcity Advantage</Typography>
                            <Typography sx={{ color: grey[400], fontSize: 15, mt: 1 }}>
                                GEN grows scarcer over time, with 7 GEN distributed per block for 52 days until the total supply reaches
                                21,000,000 GEN, making it a store of value and hedge against inflation.
                            </Typography>
                            <Grid container display="flex" justifyContent="space-between" mt={6} mb={1}>
                                <Typography sx={{ color: grey[50], fontWeight: 600, fontSize: 16 }}>Emission Rate</Typography>
                                <Typography sx={{ color: grey[50], fontWeight: 600, fontSize: 16, display: 'flex', alignItems: 'center' }}>
                                    <FaCaretUp style={{ fontSize: 20, color: '#00e676', marginRight: 5 }} /> 7 GEN/block
                                </Typography>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default GenericToken;
