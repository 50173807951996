import { injected } from './connectors';
import { grey } from '@mui/material/colors';
import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import WalletModal from 'ui-component/WalletModal';
import { Avatar, Button, Grid } from '@mui/material';
import walleticon from '../../assets/walletaccicon.png';

function formatString(str) {
    if (str.length <= 8) {
        return str;
    }
    return `${str.slice(0, 4)}...${str.slice(-4)}`;
}

const ConnButtonHeader = () => {
    const { activate, active, account, chainId } = useWeb3React();
    const [formattedAccount, setFormattedAccount] = useState('');
    const [walletModalOpen, setWalletModalOpen] = useState(false);

    const connect = async () => {
        try {
            await activate(injected);
        } catch (ex) {
            console.log(ex);
        }
    };

    useEffect(() => {
        if (account) {
            setFormattedAccount(formatString(account));
        } else {
            setFormattedAccount('');
        }
    }, [account, chainId]);
    return (
        <div>
            <Grid item sx={{ backgroundColor: 'transparent' }}>
                <Button
                    sx={{
                        fontSize: 12,
                        boxShadow: 1,
                        width: '100%',
                        color: grey[50],
                        border: 1,
                        borderColor: grey[700],
                        fontWeight: 600,
                        borderRadius: 50,
                        textTransform: 'none',
                        background: 'transparent',
                        ':hover': { background: 'transparent', borderColor: grey[100] }
                    }}
                    startIcon={<Avatar src={walleticon} alt="icon" sx={{ width: 20, height: 20 }} />}
                    onClick={() => {
                        connect();
                        if (active && account) {
                            setWalletModalOpen(true);
                        }
                    }}
                    variant="contained"
                >
                    {account ? formattedAccount : 'Connect Wallet'}
                </Button>
            </Grid>
            <WalletModal open={walletModalOpen} setOpen={setWalletModalOpen} />
        </div>
    );
};

export default ConnButtonHeader;
