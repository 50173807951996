import { useEffect } from 'react';
import GenStats from './GenStats';
import GenericToken from './GenToken';
import { useTheme } from '@mui/system';
import Introduction from './Introduction';
import GenEcosystem from './GenEcosystem';
import GenStakingStats from './GenStakingStats';
import DiscoverMore from './DiscoverMore';

const DashboardMain = () => {
    const theme = useTheme();
    useEffect(() => {
        document.title = 'Dashboard | Generic';
    });
    return (
        <>
            <Introduction />
            <GenStats />
            <GenEcosystem />
            <GenericToken />
            <GenStakingStats />
            <DiscoverMore />
        </>
    );
};

export default DashboardMain;
