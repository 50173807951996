import Web3 from 'web3';
import { GENABI } from './GENABI';
import { PancakeRouterABI } from './PancakeRouterABI';
import { PoolManagerABI } from 'components/PoolManagerABI';
import { lpPairABI } from './lpPairABI';

export const genAddress = '0x54916da9d6DF4889011eAED5730899796F4b0dBf';
export const deadWallet = '0x000000000000000000000000000000000000dEaD';
export const lpPairTokenAddress = '0x109d51FF54AEF5132C2001364eA4CC689fF449B9';
export const genManagerAddress = '0xf8CDE3BFc253653EB4b7B5fbAa0EA6c94254854d';

let selectedAccount;
let WBTC;
let lpPair;
let GENToken;
let poolManager;
let GENTokenView;
let pancakeRouter;
let isInitialized = false;
export const init = async () => {
    const provider = window.ethereum;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (typeof provider !== 'undefined') {
        provider
            .request({ method: 'eth_requestAccounts' })
            .then((accounts) => {
                selectedAccount = accounts[0];
                console.log(`Selected account is ${selectedAccount}`);
            })
            .catch((err) => {
                console.log(err);
            });

        window.ethereum.on('accountsChanged', (accounts) => {
            selectedAccount = accounts[0];
            console.log(`Selected account changed to ${selectedAccount}`);
        });
    }

    const web3 = new Web3(provider);
    const web3view = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545/');

    lpPair = new web3view.eth.Contract(lpPairABI, lpPairTokenAddress);
    WBTC = new web3.eth.Contract(GENABI, '0xd389253265dd6b85C47c410EC5fF0c6A383CE949');
    GENToken = new web3.eth.Contract(GENABI, '0x54916da9d6DF4889011eAED5730899796F4b0dBf');
    GENTokenView = new web3view.eth.Contract(GENABI, '0x54916da9d6DF4889011eAED5730899796F4b0dBf');
    poolManager = new web3.eth.Contract(PoolManagerABI, '0xf8CDE3BFc253653EB4b7B5fbAa0EA6c94254854d');
    pancakeRouter = new web3.eth.Contract(PancakeRouterABI, '0xD99D1c33F9fC3444f8101754aBC46c52416550D1');
    isInitialized = true;
};

const provider = window.ethereum;
const web3 = new Web3(provider);

// BNB Balance

export const fetchEthBalance = async (accountAddress) => {
    if (!isInitialized) {
        await init();
    }

    // Fetch the balance in Wei
    const balance = await web3.eth.getBalance(accountAddress);

    return balance;
};

export const approveGENManager = async (amount, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await GENToken.methods
            .approve('0xf8CDE3BFc253653EB4b7B5fbAa0EA6c94254854d', amount)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const fetchRewards = async (accountAddress) => {
    if (!isInitialized) {
        await init();
    }

    // Fetch the balance in Wei
    const pendingrewards = await poolManager.methods.earned(accountAddress).call();

    return pendingrewards;
};

export const fetchTotalStaked = async () => {
    if (!isInitialized) {
        await init();
    }

    // Fetch the balance in Wei
    const totalstaked = await poolManager.methods.totalStaked().call();

    return totalstaked;
};

export const fetchGENPrice = async () => {
    if (!isInitialized) {
        await init();
    }

    const reserves = await lpPair.methods.getReserves().call();

    return reserves;
};

export const fetchWBTCRewards = async (accountAddress) => {
    if (!isInitialized) {
        await init();
    }

    // Fetch the balance in Wei
    const pendingrewards = await poolManager.methods.earnedWBTC(accountAddress).call();

    return pendingrewards;
};

export const balanceOfGEN = async (accountAddress) => {
    if (!isInitialized) {
        await init();
    }

    // Fetch the balance in Wei
    const balance = await GENTokenView.methods.balanceOf(accountAddress).call();

    return balance;
};

export const balanceOfWBTC = async (accountAddress) => {
    if (!isInitialized) {
        await init();
    }

    // Fetch the balance in Wei
    const balance = await WBTC.methods.balanceOf(accountAddress).call();

    return balance;
};

export const fetchStakedBalance = async (accountAddress) => {
    if (!isInitialized) {
        await init();
    }

    // Fetch the balance in Wei
    const balance = await poolManager.methods.userStakedBalance(accountAddress).call();

    return balance;
};

export const stake = async (amount, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await poolManager.methods
            .stake(amount)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const claimRewards = async (onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await poolManager.methods
            .claimRewards()
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

// Pancake Router
export const approveGEN = async (amount, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await GENToken.methods
            .approve('0xD99D1c33F9fC3444f8101754aBC46c52416550D1', amount)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const approveWBTC = async (amount, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await WBTC.methods
            .approve('0xD99D1c33F9fC3444f8101754aBC46c52416550D1', amount)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const swapGEN = async (amountIn, path, to, deadline, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await pancakeRouter.methods
            .swapExactTokensForTokensSupportingFeeOnTransferTokens(amountIn, 0, path, to, deadline)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export { web3 };
