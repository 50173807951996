import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import { grey } from '@mui/material/colors';
import { useWeb3React } from '@web3-react/core';
import genlogo from '../../../assets/genlogo.png';
import { lightBrandColor } from 'themes/constants';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { fetchTotalStaked } from 'components/wallet/sharesABI';
import { Grid, Box, Typography, LinearProgress, Chip } from '@mui/material';

const GradientProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: 10, // Unfilled part height
    borderRadius: 3,
    backgroundColor: 'transparent',
    position: 'relative',
    overflow: 'hidden',
    '& .MuiLinearProgress-bar': {
        background: 'linear-gradient(to right, #ff6a00, #ee0979)', // Gradient for filled part
        boxShadow: '0 4px 20px rgba(238, 9, 121, 0.8)', // Adds shadow to make it stand out
        borderRadius: 8 // Rounded edges for the filled part
    }
}));

const AnalyticsOverview = () => {
    const { account } = useWeb3React();
    const [totalStaked, setTotalStaked] = useState(0);
    const [progressValue, setProgressValue] = useState(63);

    useEffect(() => {
        // Define the function to fetch rewards and update state
        const updateData = async () => {
            try {
                const staked = await fetchTotalStaked(account);
                setTotalStaked(staked);
            } catch (error) {
                console.error('Error fetching rewards:', error);
            }
        };

        updateData();
    }, [account]);
    useEffect(() => {
        document.title = 'Analytics | GenericDAO';
    });
    return (
        <>
            <Grid
                container
                sx={{
                    py: 2,
                    mt: 15,
                    zIndex: 999,
                    display: 'flex',
                    borderRadius: 5,
                    justifyContent: 'space-between',
                    px: { lg: 5, md: 5, sm: 5, xs: 3 },
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                    width: { lg: 700, md: 700, sm: '90%', xs: '97.5%' },
                    background: 'linear-gradient(to right bottom, #070707, #272727)'
                }}
            >
                <Typography sx={{ color: grey[100], fontWeight: 700, fontSize: 24 }}>Overview</Typography>
                <Grid container display="flex">
                    <Typography sx={{ color: grey[400], fontWeight: 400, fontSize: 14 }}>
                        Earn Bitcoin and GEN interest through staking your GEN or USDC tokens.
                    </Typography>
                </Grid>
                <Grid container display="flex" justifyContent="space-between" mt={3} mb={1}>
                    <Box
                        sx={{
                            p: 2,
                            border: 1,
                            display: 'flex',
                            borderRadius: 4,
                            background: '#101010',
                            borderColor: '#272727',
                            width: { lg: '49%', md: '49%', sm: '100%', xs: '100%' }
                        }}
                    >
                        <Grid container sx={{ display: 'flex', alignItems: 'center', width: 'auto' }}>
                            <Box
                                sx={{
                                    width: 57,
                                    height: 57,
                                    display: 'flex',
                                    borderRadius: '50%',
                                    alignItems: 'center',
                                    background: '#303030',
                                    justifyContent: 'center',
                                    filter: 'brightness(120%)'
                                }}
                            >
                                <img src={genlogo} alt="logo" width="100%" />
                            </Box>
                            <Grid container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: 'auto', ml: 1.5 }}>
                                <Typography sx={{ color: grey[50], fontSize: 14 }}>Total GEN locked</Typography>
                                <Typography sx={{ color: grey[50], fontSize: 24, fontWeight: 700 }}>63.28%</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        sx={{
                            p: 2,
                            border: 1,
                            display: 'flex',
                            borderRadius: 4,
                            background: '#101010',
                            borderColor: '#272727',
                            mt: { lg: 0, md: 0, sm: 2, xs: 2 },
                            width: { lg: '49%', md: '49%', sm: '100%', xs: '100%' }
                        }}
                    >
                        <Grid container sx={{ display: 'flex', alignItems: 'center', width: 'auto' }}>
                            <Box
                                sx={{
                                    width: 57,
                                    height: 57,
                                    display: 'flex',
                                    borderRadius: '50%',
                                    alignItems: 'center',
                                    background: '#303030',
                                    justifyContent: 'center'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '50%',
                                        height: '50%',
                                        display: 'flex',
                                        overflow: 'hidden',
                                        borderRadius: '50%',
                                        alignItems: 'center',
                                        background: '#505050',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <TrendingUpIcon sx={{ color: lightBrandColor, ml: -1.5, fontSize: 30, mb: -0.5 }} />
                                </Box>
                            </Box>
                            <Grid container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: 'auto', ml: 1.5 }}>
                                <Typography sx={{ color: grey[50], fontSize: 14 }}>Daily emission rate</Typography>
                                <Typography sx={{ color: grey[50], fontSize: 24, fontWeight: 700 }}>201.6K GEN</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid container width="100%" my={4} sx={{ ml: { lg: 2, md: 2, sm: 2, xs: 0 } }} display="flex">
                    <Grid
                        container
                        display="flex"
                        flexDirection="column"
                        sx={{
                            borderLeft: { lg: 0, md: 0, sm: 0, xs: 1 },
                            borderColor: { xs: grey[800] },
                            pl: { lg: 0, md: 0, sm: 0, xs: 2 },
                            width: { lg: 'auto', md: 'auto', sm: 'auto', xs: '100%' }
                        }}
                    >
                        <Typography sx={{ color: grey[500], fontWeight: 800, fontSize: 16 }}>Price</Typography>
                        <Typography sx={{ color: grey[50], fontSize: 16 }}>$0.02</Typography>
                    </Grid>
                    <Grid
                        container
                        display="flex"
                        flexDirection="column"
                        sx={{
                            my: { lg: 0, md: 0, sm: 0, xs: 2 },
                            pl: { lg: 4, md: 4, sm: 4, xs: 2 },
                            pr: { lg: 4, md: 4, sm: 4, xs: 0 },
                            mx: { lg: 4, md: 4, sm: 4, xs: 0 },
                            borderLeft: { lg: 1.3, md: 1.3, sm: 1.3, xs: 1 },
                            borderRight: { lg: 1.3, md: 1.3, sm: 1.3, xs: 0 },
                            width: { lg: 'auto', md: 'auto', sm: 'auto', xs: '100%' },
                            borderColor: { xs: grey[800], lg: grey[800], md: grey[800], sm: grey[800] }
                        }}
                    >
                        <Typography sx={{ color: grey[500], fontWeight: 800, fontSize: 16 }}>Market Cap</Typography>
                        <Typography sx={{ color: grey[50], fontSize: 16 }}>$148.28K</Typography>
                    </Grid>
                    <Grid
                        container
                        display="flex"
                        flexDirection="column"
                        sx={{
                            borderLeft: { lg: 0, md: 0, sm: 0, xs: 1 },
                            borderColor: { xs: grey[800] },
                            pl: { lg: 0, md: 0, sm: 0, xs: 2 },
                            width: { lg: 'auto', md: 'auto', sm: 'auto', xs: '100%' }
                        }}
                    >
                        <Typography sx={{ color: grey[500], fontWeight: 800, fontSize: 16 }}>Current Fee</Typography>
                        <Typography sx={{ color: grey[50], fontSize: 16 }}>2%</Typography>
                    </Grid>
                </Grid>
                <Grid container display="flex" lg={12} justifyContent="center" mt={5}>
                    <Box sx={{ width: '100%', justifyContent: 'center', overflow: 'hidden', zIndex: 998 }}>
                        <GradientProgressBar value={progressValue} variant="determinate" />
                    </Box>
                    <Grid container lg={12} sx={{ height: 2, bgcolor: grey[800], position: 'relative', mt: -1, zIndex: 9 }} />
                    <Grid container>
                        <Grid container width="auto" sx={{ position: 'relative', left: `${progressValue - 1.5}%`, zIndex: 999, mt: -2.3 }}>
                            <Grid container zIndex={999}>
                                <img src={genlogo} alt="logo" height={25} width={25} />
                            </Grid>
                            <Grid container justifyContent="center" display="flex" zIndex={998} ml={-2.55}>
                                <Typography sx={{ color: grey[800], mt: -1.5, ml: -0.2 }}>|</Typography>
                            </Grid>
                            <Grid container justifyContent="center" display="flex" width={100} zIndex={998} ml={-5}>
                                <Typography sx={{ color: grey[50], fontSize: 14, fontWeight: 700 }}>{progressValue}% staked</Typography>
                            </Grid>
                        </Grid>
                        <Grid container width="auto" sx={{ position: 'relative', left: -68 }}>
                            <Grid container justifyContent="center" display="flex" zIndex={997}>
                                <Typography sx={{ color: grey[800], mt: -1.5, ml: -0.2 }}>|</Typography>
                            </Grid>
                            <Typography sx={{ color: grey[50], fontSize: 14, fontWeight: 700 }}>$0</Typography>
                        </Grid>
                        <Grid container width="100%" justifyContent="right" display="flex" mt={-5}>
                            <Grid container alignItems="end" display="flex" zIndex={997} flexDirection="column" mr={-2}>
                                <Typography textAlign="center" sx={{ color: grey[800], mt: -1.5, mr: 1.85 }}>
                                    |
                                </Typography>
                                <Typography sx={{ color: grey[50], fontSize: 14, fontWeight: 700, mt: 0.5, mr: 0.8 }}>100%</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{
                        mt: 3,
                        mb: 1,
                        py: 2,
                        px: 3,
                        border: 1,
                        width: '100%',
                        display: 'flex',
                        borderRadius: 3,
                        background: '#101010',
                        borderColor: '#272727',
                        justifyContent: 'space-between',
                        mx: { lg: 2, md: 2, sm: 2, xs: 0 }
                    }}
                >
                    <Grid container sx={{ width: { lg: '50%', md: '57%', sm: '60%', xs: '100%' } }}>
                        <Typography sx={{ fontSize: 14, color: grey[100], fontWeight: 500 }}>GEN Distribution End</Typography>
                        <Chip
                            label="28 Mar"
                            size="small"
                            sx={{ px: 0.5, background: '#262525', border: 1, borderColor: '#343434', ml: 2 }}
                        />
                        <Grid container sx={{ mt: { lg: 0, md: 0, sm: 0, xs: 1 }, pr: { lg: 4, md: 4, sm: 4, xs: 2 } }}>
                            <Typography sx={{ fontSize: 12, color: grey[100] }}>
                                Stake GEN to earn protocol revenue in GEN and BTC distributed instantly.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        borderColor={grey[800]}
                        sx={{
                            mt: { lg: 0, md: 0, sm: 0, xs: 2 },
                            pl: { lg: 3, md: 3, sm: 3, xs: 0 },
                            borderLeft: { lg: 1, md: 1, sm: 1, xs: 0 },
                            width: { lg: '50%', md: '42%', sm: '40%', xs: '100%' }
                        }}
                    >
                        <Typography sx={{ fontSize: 16, color: grey[300], ml: 0.3 }}>Distributed Tokens</Typography>
                        <Grid container display="flex" alignItems="center">
                            <img src={genlogo} alt="logo" width={25} height={25} />
                            <Typography sx={{ fontSize: { lg: 20, md: 18, sm: 18, xs: 18 }, color: grey[50], ml: 1, fontWeight: 900 }}>
                                0.00
                            </Typography>
                            <Typography sx={{ fontSize: { lg: 20, md: 18, sm: 18, xs: 18 }, color: grey[500], ml: 1, fontWeight: 900 }}>
                                / 10.0M
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default AnalyticsOverview;
