import { useState } from 'react';
import LogoSection from '../LogoSection';
import MenuList from '../Sidebar/MenuList';
import { grey } from '@mui/material/colors';
import { Grid, Typography } from '@mui/material';
import MenuDrawer from 'views/utilities/MenuDrawer';
import ConnButtonHeader from 'components/wallet/ConnButtonHeader';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);
    const closeMenuDrawer = () => {
        setIsMenuDrawerOpen(false);
    };

    return (
        <>
            <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid
                    container
                    sx={{
                        py: { lg: 0.8, md: 0.8, sm: 2, xs: 2 },
                        display: 'flex',
                        background: '#000',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <Grid item display="flex" alignItems="center" justifyContent="left" ml={4}>
                        <LogoSection />
                        <Typography
                            sx={{
                                color: grey[50],
                                fontSize: 26,
                                ml: -1,
                                mb: 0.75,
                                fontWeight: 800,
                                letterSpacing: '-1.5px',
                                display: { lg: 'unset', md: 'unset', sm: 'none', xs: 'none' }
                            }}
                        >
                            GENERIC
                        </Typography>
                    </Grid>
                    <Grid item sx={{ display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' } }}>
                        <MenuList />
                    </Grid>
                    <Grid item xs="auto" display="flex" justifyContent="right" alignItems="center" mr={5}>
                        <ConnButtonHeader />
                    </Grid>
                </Grid>
            </Grid>
            <MenuDrawer isOpen={isMenuDrawerOpen} onClose={closeMenuDrawer} />
        </>
    );
};

export default Header;
