import { useEffect } from 'react';
import { Grid } from '@mui/material';
import GovernanceStats from './GovernanceStats';
import bg from '../../../assets/DashboardBanner.png';
import GovernanceActiveCard from './GovernanceActiveCard';

const Governance = () => {
    useEffect(() => {
        document.title = 'Governance | Generic';
    });
    return (
        <>
            <Grid
                item
                sx={{
                    top: 0,
                    zIndex: 1,
                    width: '90%',
                    height: '100%',
                    display: 'flex',
                    overflow: 'hidden',
                    position: 'absolute',
                    justifyContent: 'center'
                }}
            >
                <img
                    src={bg} // Path to your background image
                    alt="Background"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensures the image covers the entire container
                        filter: 'brightness(93%)'
                    }}
                />
            </Grid>
            <Grid container display="flex" justifyContent="center" mt={20}>
                <GovernanceActiveCard />
            </Grid>
            <Grid container display="flex" justifyContent="center" mt={-5}>
                <GovernanceStats />
            </Grid>
        </>
    );
};

export default Governance;
