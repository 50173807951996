import '../BlinkingText.css';
import { useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import genlogo from '../../../assets/genlogo.png';
import baselogo from '../../../assets/BaseLogo.png';
import bg from '../../../assets/DashboardBanner.png';
import { Grid, Typography, Box } from '@mui/material';

const Introduction = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container width="100%" bgcolor="#000" height={100} zIndex={9} />
            <Grid
                container
                display="flex"
                justifyContent="center"
                pt={10}
                sx={{ mt: { lg: -2.8, md: -2.8, sm: -4, xs: -4 } }}
                bgcolor="#f8f8ff"
                borderRadius={7}
                zIndex={999}
            >
                <Grid
                    item
                    sx={{
                        position: 'absolute', // Stays behind content
                        top: 0,
                        justifyContent: 'center',
                        display: 'flex',
                        width: '90%',
                        height: '100%',
                        overflow: 'hidden'
                    }}
                >
                    <img
                        src={bg} // Path to your background image
                        alt="Background"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover', // Ensures the image covers the entire container
                            filter: 'brightness(93%)'
                        }}
                    />
                </Grid>
                <Grid container display="flex" justifyContent="center" zIndex={999}>
                    <Box
                        sx={{
                            background: '#ecebf5',
                            pl: 2,
                            pr: 0.5,
                            py: 0.5,
                            border: 1,
                            display: 'flex',
                            borderRadius: 50,
                            flexDirection: 'row',
                            borderColor: '#D9D9D9',
                            alignItems: 'center'
                        }}
                    >
                        <Typography sx={{ fontSize: 14, color: grey[900], fontWeight: 500, mr: 0.8, py: 0.5 }}>$GEN is live on</Typography>
                        <img src={baselogo} alt="logo" height={20} />
                        <Box
                            sx={{
                                ml: 1,
                                px: 1.5,
                                height: '100%',
                                display: 'flex',
                                borderRadius: 50,
                                background: '#fff',
                                alignItems: 'center',
                                flexDirection: 'row'
                            }}
                        >
                            <img src={genlogo} alt="logo" width={17} height={17} />
                            <Typography sx={{ fontSize: 14, color: grey[900], fontWeight: 500, ml: 0.8, mr: 0.3 }}>$0.04</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid container display="flex" justifyContent="center" zIndex={999} mt={2}>
                    <Grid container lg={10} xs={11} justifyContent="center" display="flex">
                        <Typography textAlign="center" sx={{ fontSize: 40, fontWeight: 700, color: grey[900], lineHeight: 1 }}>
                            Forged together, shared with all.
                        </Typography>
                    </Grid>
                    <Grid container justifyContent="center" display="flex" sx={{ width: { lg: 450, md: 450, sm: 450, xs: '95%' }, mt: 3 }}>
                        <Typography textAlign="center" sx={{ fontSize: 14, color: grey[900] }}>
                            Fueling revenue, safeguarding value - Generic transforms liquid assets into Bitcoin rewards for the entire
                            ecosystem.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Introduction;
