import { useRoutes } from 'react-router-dom';

// routes
import MainLayout from 'layout/MainLayout';
import Governance from 'views/utilities/Governance/Gov';
import Dashboard from 'views/utilities/Dashboard/Dashboard';
import Analytics from 'views/utilities/Analytics/Analytics';
import Staking from 'views/utilities/Staking/Staking';
import Predictions from 'views/utilities/Predictions/Predictions';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        {
            path: '/',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <Dashboard />
                }
            ]
        },
        {
            path: '/dashboard',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <Dashboard />
                }
            ]
        },
        {
            path: '/governance',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <Governance />
                }
            ]
        },
        {
            path: '/stake',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <Staking />
                }
            ]
        },
        {
            path: '/analytics',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <Analytics />
                }
            ]
        },
        {
            path: '/predictions',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <Predictions />
                }
            ]
        }
    ]);
}
