import { grey } from '@mui/material/colors';
import { Button, Grid, Typography } from '@mui/material';
import genlogo from '../../../assets/genlogo.png';
import CallMadeRoundedIcon from '@mui/icons-material/CallMadeRounded';

const GenDescription = () => {
    const web3 = 23;

    return (
        <>
            <Grid container lg={9} md={10} sm={11} xs={11.5} display="flex" mt={10}>
                <Typography sx={{ color: grey[900], fontSize: 16, fontWeight: 500, ml: 2 }}>Generic Token</Typography>
            </Grid>
            <Grid
                container
                lg={9}
                md={10}
                sm={11}
                xs={11.5}
                sx={{
                    pb: 4,
                    mx: 1,
                    mt: 2,
                    zIndex: 999,
                    display: 'flex',
                    borderRadius: 5,
                    overflow: 'hidden',
                    background: '#fff',
                    position: 'relative',
                    justifyContent: 'space-between',
                    px: { lg: 5, md: 5, sm: 5, xs: 2 },
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
                }}
            >
                <Grid
                    container
                    sx={{
                        width: { lg: '50%', md: '50%', sm: '100%', xs: '100%' },
                        display: 'flex',
                        alignItems: 'center',
                        mt: 6,
                        px: { lg: 0, md: 0, sm: 0, xs: 3 }
                    }}
                >
                    <img src={genlogo} alt="logo" width={35} height={35} />
                    <Typography sx={{ color: grey[900], fontSize: 20, fontWeight: 800, ml: 2 }}>$GEN, Store of Value Token</Typography>
                    <Grid container mt={2}>
                        <Typography sx={{ color: grey[900], fontSize: 16 }}>
                            The GEN token is a store of value that allows users to stake for high-yield Bitcoin and GEN rewards or vote on
                            the transaction fees.
                        </Typography>
                    </Grid>
                    <Grid container my={4}>
                        <Button
                            size="small"
                            endIcon={<CallMadeRoundedIcon />}
                            sx={{ border: 1, borderRadius: 50, px: 2, borderColor: '#272727', color: '#272727', textTransform: 'none' }}
                        >
                            Learn more
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{
                        top: -20,
                        right: 20,
                        position: 'absolute',
                        justifyContent: 'right',
                        width: { lg: '50%', md: '50%' },
                        display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' }
                    }}
                >
                    <img src={genlogo} alt="logo" width="40%" />
                </Grid>
                <Grid
                    container
                    mt={3}
                    display="flex"
                    sx={{ justifyContent: { lg: 'space-between', md: 'space-between', sm: 'center', xs: 'center' } }}
                >
                    <Grid
                        container
                        sx={{
                            py: 2,
                            px: 3,
                            borderRadius: 4,
                            display: 'flex',
                            background: '#f8f8f8',
                            justifyContent: 'space-between',
                            width: { lg: '33%', md: '33%', sm: '49%', xs: '100%' }
                        }}
                    >
                        <Typography sx={{ color: grey[900], fontWeight: 700, fontSize: 20 }}>Earn & Decide</Typography>
                        <Grid container mt={1}>
                            <Typography sx={{ color: grey[500], fontSize: 14 }}>
                                $GEN stakers not only earn significant rewards but also shape the platform by voting on transaction fees and
                                other critical governance matters.
                            </Typography>
                        </Grid>
                        <Typography sx={{ color: grey[900], fontWeight: 500, fontSize: 16, mt: 5 }}>TVL</Typography>
                        <Typography sx={{ color: grey[900], fontWeight: 500, fontSize: 16, mt: 5 }}>$14,853</Typography>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            py: 2,
                            px: 3,
                            borderRadius: 4,
                            display: 'flex',
                            background: '#f8f8f8',
                            justifyContent: 'space-between',
                            ml: { lg: 0, md: 0, sm: 1, xs: 0 },
                            my: { lg: 0, md: 0, sm: 0, xs: 1 },
                            width: { lg: '33%', md: '33%', sm: '49%', xs: '100%' }
                        }}
                    >
                        <Typography sx={{ color: grey[900], fontWeight: 700, fontSize: 20 }}>Backed by Bitcoin</Typography>
                        <Grid container mt={1}>
                            <Typography sx={{ color: grey[500], fontSize: 14 }}>
                                Backed by Bitcoin liquidity, $GEN offers a secure and inflation-resistant asset for long-term value
                                preservation.
                            </Typography>
                        </Grid>
                        <Typography sx={{ color: grey[900], fontWeight: 500, fontSize: 16, mt: 5 }}>Total Liquidity</Typography>
                        <Typography sx={{ color: grey[900], fontWeight: 500, fontSize: 16, mt: 5 }}>$21,771</Typography>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            py: 2,
                            px: 3,
                            zIndex: 999,
                            borderRadius: 4,
                            display: 'flex',
                            background: '#f8f8f8',
                            justifyContent: 'space-between',
                            mt: { lg: 0, md: 0, sm: 1, xs: 0 },
                            mb: { lg: 0, md: 0, sm: 0, xs: -2 },
                            width: { lg: '33%', md: '33%', sm: '50%', xs: '100%' }
                        }}
                    >
                        <Typography sx={{ color: grey[900], fontWeight: 700, fontSize: 20 }}>Limited Supply</Typography>
                        <Grid container mt={1}>
                            <Typography sx={{ color: grey[500], fontSize: 14 }}>
                                $GEN`s fixed supply of 21 million GEN tokens ensures scarcity and positions the token as a reliable store of
                                value, similar to Bitcoin.
                            </Typography>
                        </Grid>
                        <Typography sx={{ color: grey[900], fontWeight: 500, fontSize: 16, mt: 5 }}>$GEN Price</Typography>
                        <Typography sx={{ color: grey[900], fontWeight: 500, fontSize: 16, mt: 5 }}>$0.02</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default GenDescription;
