import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, CssBaseline, Toolbar, Slide } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import navigation from 'menu-items';

// assets
import { IconChevronRight } from '@tabler/icons';
import Footer from './Footer';
import MobileHeader from './Header/MobileHeader';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
    ...theme.typography.mainContent
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();

    return (
        <>
            <CssBaseline />
            <AppBar
                position="absolute"
                enableColorOnDark
                sx={{
                    top: 0,
                    width: '100%',
                    boxShadow: 0,
                    bottom: 'auto',
                    background: '#000'
                }}
            >
                <Toolbar>
                    <Header />
                </Toolbar>
            </AppBar>
            <Main theme={theme}>
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
            <AppBar
                position="fixed"
                enableColorOnDark
                sx={{
                    top: 'auto',
                    bottom: 10,
                    boxShadow: 0,
                    background: 'transparent',
                    display: { lg: 'none', md: 'none', sm: 'flex', xs: 'flex' }
                }}
            >
                <MobileHeader />
            </AppBar>
            <Footer />
        </>
    );
};

export default MainLayout;
