import { useState } from 'react';
import { styled } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import genlogo from '../../../assets/genlogo.png';
import { green, grey } from '@mui/material/colors';
import CircleIcon from '@mui/icons-material/Circle';
import { LinearProgress, Grid, Typography, Box } from '@mui/material';

const GradientProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: 10, // Unfilled part height
    borderRadius: 3,
    backgroundColor: 'transparent',
    position: 'relative',
    overflow: 'hidden',
    '& .MuiLinearProgress-bar': {
        background: 'linear-gradient(to right, #ff6a00, #ee0979)', // Gradient for filled part
        boxShadow: '0 4px 20px rgba(238, 9, 121, 0.8)', // Adds shadow to make it stand out
        borderRadius: 8 // Rounded edges for the filled part
    }
}));

const GenStats = () => {
    const [progressValue, setProgressValue] = useState(63);

    return (
        <>
            <Grid container display="flex" justifyContent="center" mt={15}>
                <Grid container display="flex" lg={9} md={10} sm={11} xs={11.5} ml={4}>
                    <Typography textAlign="center" sx={{ fontSize: 16, color: grey[900], fontWeight: 600 }}>
                        Generic Global Stats
                    </Typography>
                </Grid>
                <Grid
                    container
                    lg={9}
                    md={10}
                    sm={11}
                    xs={11.5}
                    px={5}
                    py={4}
                    mt={2}
                    zIndex={999}
                    display="flex"
                    justifyContent="space-between"
                    sx={{ background: `linear-gradient(to right bottom, #000, #292929)`, borderRadius: 7 }}
                >
                    <Grid container display="flex" width="auto">
                        <Grid container width="33%">
                            <Grid container display="flex" alignItems="center">
                                <Typography sx={{ fontSize: { lg: 20, md: 20, sm: 18, xs: 16 }, color: grey[400], fontWeight: 500 }}>
                                    TVL
                                </Typography>
                                <InfoIcon sx={{ fontSize: 13, color: grey[400], ml: 1 }} />
                            </Grid>
                            <Typography sx={{ fontSize: { lg: 24, md: 24, sm: 22, xs: 20 }, color: grey[50], fontWeight: 800 }}>
                                $ 10.8K
                            </Typography>
                        </Grid>
                        <Grid container width="33%">
                            <Grid container display="flex" alignItems="center">
                                <Typography sx={{ fontSize: { lg: 20, md: 20, sm: 18, xs: 16 }, color: grey[400], fontWeight: 500 }}>
                                    Revenue
                                </Typography>
                                <InfoIcon sx={{ fontSize: 13, color: grey[400], ml: 1 }} />
                            </Grid>
                            <Typography sx={{ fontSize: { lg: 24, md: 24, sm: 22, xs: 20 }, color: grey[50], fontWeight: 800 }}>
                                $ 23.2K
                            </Typography>
                        </Grid>
                        <Grid container width="33%">
                            <Grid container display="flex" alignItems="center">
                                <Typography sx={{ fontSize: { lg: 20, md: 20, sm: 18, xs: 16 }, color: grey[400], fontWeight: 500 }}>
                                    Users
                                </Typography>
                                <InfoIcon sx={{ fontSize: 13, color: grey[400], ml: 1 }} />
                            </Grid>
                            <Typography sx={{ fontSize: { lg: 24, md: 24, sm: 22, xs: 20 }, color: grey[50], fontWeight: 800 }}>
                                39
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        width="auto"
                        alignItems="center"
                        justifyContent="right"
                        sx={{ display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' } }}
                    >
                        <img src={genlogo} alt="logo" width={20} height={20} />
                        <Typography sx={{ fontWeight: 800, fontSize: 20, color: grey[50], ml: 1.3 }}>Generic Token</Typography>
                        <Grid container display="flex" justifyContent="right" alignItems="center">
                            <Typography sx={{ fontWeight: 500, fontSize: 16, color: grey[400], mt: 0.3 }}>Market Cap</Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: 20, color: grey[50], ml: 1 }}>$ 128.39K</Typography>
                        </Grid>
                    </Grid>
                    <Grid container display="flex" lg={12} justifyContent="center" mt={5}>
                        <Box sx={{ width: '100%', justifyContent: 'center', overflow: 'hidden', zIndex: 998 }}>
                            <GradientProgressBar value={progressValue} variant="determinate" />
                        </Box>
                        <Grid container lg={12} sx={{ height: 2, bgcolor: grey[800], position: 'relative', mt: -1, zIndex: 9 }} />
                        <Grid container>
                            <Grid
                                container
                                width="auto"
                                sx={{ position: 'relative', left: `${progressValue - 1.5}%`, zIndex: 999, mt: -2.3 }}
                            >
                                <Grid container zIndex={999}>
                                    <img src={genlogo} alt="logo" height={25} width={25} />
                                </Grid>
                                <Grid container justifyContent="center" display="flex" zIndex={998} ml={-2.55}>
                                    <Typography sx={{ color: grey[800], mt: -1.5, ml: -0.2 }}>|</Typography>
                                </Grid>
                                <Grid container justifyContent="center" display="flex" width={100} zIndex={998} ml={-5}>
                                    <Typography sx={{ color: grey[50], fontSize: 14, fontWeight: 700 }}>{progressValue}% staked</Typography>
                                </Grid>
                            </Grid>
                            <Grid container width="auto" sx={{ position: 'relative', left: -68 }}>
                                <Grid container justifyContent="center" display="flex" zIndex={997}>
                                    <Typography sx={{ color: grey[800], mt: -1.5, ml: -0.2 }}>|</Typography>
                                </Grid>
                                <Typography sx={{ color: grey[50], fontSize: 14, fontWeight: 700 }}>$0</Typography>
                            </Grid>
                            <Grid container width="100%" justifyContent="right" display="flex" mt={-7.35}>
                                <Grid container zIndex={999} display="flex" justifyContent="right" mr={-0.5}>
                                    <CircleIcon sx={{ color: green[400], fontSize: 23 }} />
                                </Grid>
                                <Grid container alignItems="end" display="flex" zIndex={997} flexDirection="column" mr={-2}>
                                    <Typography textAlign="center" sx={{ color: grey[800], mt: -1.5, mr: 2.5 }}>
                                        |
                                    </Typography>
                                    <Typography sx={{ color: grey[50], fontSize: 14, fontWeight: 700 }}>100%</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        mt={4}
                        width="100%"
                        alignItems="center"
                        sx={{ display: { lg: 'none', md: 'none', sm: 'flex', xs: 'flex' }, justifyContent: 'center' }}
                    >
                        <Grid container display="flex" width="25%" alignItems="center">
                            <img src={genlogo} alt="logo" width={20} height={20} />
                            <Typography sx={{ fontWeight: 800, fontSize: { lg: 20, md: 20, sm: 20, xs: 16 }, color: grey[50] }}>
                                GEN
                            </Typography>
                        </Grid>
                        <Grid container display="flex" width="75%" justifyContent="right" alignItems="center" mb={0.5}>
                            <Typography sx={{ fontWeight: 500, fontSize: 16, color: grey[400], mt: 0.3 }}>Market Cap</Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: 20, color: grey[50], ml: 1 }}>$ 128.39K</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default GenStats;
